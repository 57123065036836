<template>
 <CCard>
  <CCardBody>
    <CDataTable :items="items"  
    :fields="fields"
     striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       
      pagination>
        <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>
       <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
       <template #firstName="{item}">
        <td class='font-weight-bold'
          v-if="item.firstName && item.lastName"
        >{{ item.firstName }} {{item.lastName }}</td>
        <td v-else>--</td>
     </template>
     <template #status="{ item }">
          <td class='font-weight-bold' v-show="item.status == 1">Pending</td>
          <td class='font-weight-bold' v-show="item.status == 2">Completed</td>
      
        </template>
         <template #statusRange="{ item }">
          <td class='font-weight-bold' v-show="item.statusRange == 1">Urgent</td>
          <td class='font-weight-bold' v-show="item.statusRange == 2">Normal</td>
          <td class='font-weight-bold' v-show="item.statusRange == 3">Super Urgent</td>
        </template>
        <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                  <CDropdownItem :to="{name: 'Defects', params: {qmsJobId: item.qmsJobId}}">Defects </CDropdownItem>
           
                             </CDropdown> 

        
        </td>
      </template>
      </CDataTable>
  </CCardBody>
   <div ref="modalArea"></div>
  <CCardFooter align="right">

            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
 </CCard>
</template>

<script>
const fields = [
   { key: "index", _classes: 'font-weight-bold', _style: "min-width:200px" },
 { key: "inspectionArea", label: "Inspection Area", _classes: 'font-weight-bold', _style: "min-width:200px" },
    { key: "description", label: "Description", _classes: 'font-weight-bold', _style: "min-width:200px" },
     { key: "craneNo", label: "Crane No", _classes: 'font-weight-bold', _style: "min-width:200px" },
      { key: "jobNo", label: "Job No", _classes: 'font-weight-bold', _style: "min-width:200px" },
       { key: "timeAllowed", label: "Allowed Time", _classes: 'font-weight-bold', _style: "min-width:200px" },
        { key: "status", label: "Status",_classes: 'font-weight-bold', _style: "min-width:200px" },
        { key: "statusRange", label: "Status Range", _classes: 'font-weight-bold', _style: "min-width:200px" },
//          { key: "startDate", label: "Start Date", _classes: 'font-weight-bold', _style: "min-width:200px" },
//    { key: "startTime", label: "Start Time", _classes: 'font-weight-bold', _style: "min-width:200px" },
//     { key: "endDate", label: "End Date", _classes: 'font-weight-bold', _style: "min-width:200px" },
//      { key: "endTime", label: "End Time", _classes: 'font-weight-bold', _style: "min-width:200px" },

  
  { key: "createdTime",_classes: 'font-weight-bold', _style: "min-width:200px" },
  { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];
import Vue from "vue";
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "QmsJobs",
  components: {
     ConfirmationModal,
   },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getQmsJobs() {
       this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/qms/jobs/")
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
 cancel() {
   
             this.$router.push({ name: "Groups" });
    },
    navToNewSymptoms(){
       this.$router.push({ name: "QmsJobs", params: { mode: "new" } });
    }
  },
  mounted() {
    this.getQmsJobs();
  },
};
</script>